import React, { lazy } from "react";
import { Routes, Route } from "react-router-dom";

const Layout = lazy(() => import("../components/layout/Layout.jsx"));

const Home = lazy(() => import('../pages/Home/Home.jsx'));

const ComingSoon = lazy(() => import('../pages/ComingSoon/ComingSoon.jsx'));
const About = lazy(() => import('../pages/About/about.jsx'));
const Error = lazy(() => import("../pages/Error/Error.jsx"));

const AppRoutes = () => {
    const targetDate = '2025-02-28T23:59:59';
    return (
        <Routes>
            <Route exact path='/' element={<Layout />}>
               {/*  <Route index element={<Home />}></Route>
                <Route path="/about" element={<About />}></Route> */}
                <Route index element={<ComingSoon targetDate={targetDate}/>}></Route>
                
            </Route>

            <Route path='*' element={<Error />}></Route>
        </Routes>
    );
};

export default AppRoutes;
